import * as React from "react"
import * as styles from './CenteredSection.module.scss'
import '../../styles/columnBreakpoints.scss';
import ReactMarkdown from "react-markdown";
import rehypeRaw from 'rehype-raw';

const CenteredSection = ({content, children}) => {
  return (
    <div className={`column ${styles.column}`}>
      <ReactMarkdown rehypePlugins={[rehypeRaw]} children={content} linkTarget={'_blank'}/>
      {children}
    </div>
  )
}

export default CenteredSection;